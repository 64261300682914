﻿$background: #f4f2f7;


$primary: #c70039;
$secondary: #3d3d6b;
$info: #3FA7D6;
$success: #59cd90;
$warning: #FAC05E;
$error: #900c3f;

:export{
  primary: $primary;
  secondary: $secondary;
  info: $info;
  success: $success;
  warning: $warning;
  error: $error;
  background: $background;
}
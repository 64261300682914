@import "colours.module";

$borderRadius: 8px;
$font: "Inter", sans-serif;

:export {
  borderRadius: $borderRadius;
  font: $font;
}

body, html, #root{
  min-height: 100.0vh;
  background-color: $background;
}